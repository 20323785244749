<template>
  <div>
    <ProjectTitlePreloader  v-if="state.preload"/>
    <div
      v-else
      class="project_title"
      :style="{
        'background-image': 'url(' + state.projectInfo.image_file_name + ')',
      }"
    >
      <div class="content">
        <div class="box-text">
          <h3>
            {{ state.projectInfo.title }}
          </h3>
          <p>
            <pre>{{ state.projectInfo.subtitle }}</pre>
          </p>
        </div>
        <button class="shadow" v-if="props.editMode || props.createMode" @click="state.showPopupCroppie = true">画像を変更</button>
      </div>
    </div>
    <PopupCroppie v-if="state.showPopupCroppie" type="project" :typeFile="props.createMode ? 'base64' : 'link'" :image="state.projectInfo.image_file_name" :id="state.projectInfo.project_id" :boundary="{width: 750, height: 300}" :viewport="{width: 720, height: 150}" @close="state.showPopupCroppie = false" @changeImage="onFileChange"/>
  </div>
</template>

<script lang="ts" src="@/presentation/components/project-title/ProjectTitle.ts">
</script>

<style lang="scss" src="@/presentation/components/project-title/ProjectTitle.scss" scoped>
</style>Pro
