<template>
    <div class="project_apply">
        <Navigation />
        <Breadcrumb :breadcrumb="state.listBreadcrumb"/>
        <ProjectTitle />
        <div class="container">
            <div class="main-area">
                <router-view :listBreadcrumb="state.listBreadcrumb"></router-view>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/views/user/project-apply/ProjectApply.ts">
</script>

<style lang="scss" src="@/presentation/views/user/project-apply/ProjectApply.scss" scoped>
</style>
