import { defineComponent, reactive, onMounted, watchEffect } from "vue";
import { useStore } from "vuex";
import ProjectTitlePreloader from "@/presentation/components/preloader/project-title-preloader/ProjectTitlePreloader.vue";
import PopupCroppie from "@/presentation/components/popup-croppie/PopupCroppie.vue";
import DIContainer from "@/core/DIContainer";
import OrganizationProjectInteractor from '@/domain/usecases/OrganizationProjectInteractor';
export default defineComponent({
    name: "ProjectTitle",
    components: { ProjectTitlePreloader: ProjectTitlePreloader, PopupCroppie: PopupCroppie },
    props: ["editMode", "createMode"],
    setup: function (props, _a) {
        var emit = _a.emit;
        var state = reactive({
            projectInfo: {},
            preload: true,
            showPopupCroppie: false
        });
        var store = useStore();
        var organizationProjectInteractor = DIContainer.instance.get(OrganizationProjectInteractor);
        onMounted(function () {
            if (props.createMode) {
                return state.preload = false;
            }
            store.subscribe(function (mutation, stateVuex) {
                if (mutation.type === "setProjectInfo") {
                    state.projectInfo = stateVuex.projectInfo;
                    state.preload = false;
                }
            });
        });
        watchEffect(function () {
            store.subscribe(function (mutation, stateVuex) {
                if (mutation.type === "setProjectInfo") {
                    state.projectInfo = stateVuex.projectInfo;
                    state.preload = false;
                }
            });
        });
        function onFileChange(e) {
            state.projectInfo.image_file_name = e;
            emit("changeImageProject", e);
        }
        return {
            state: state,
            store: store,
            props: props,
            onFileChange: onFileChange
        };
    }
});
