<template>
<div class="nav">
    <div class="nav_bar" v-if="store.state.isLoggin">
        <div class="nav_bar__container">
            <div class="nav_bar__container_left" v-if="state.isUser">
                <router-link :to="{ path: routePath.USER_DASHBOARD }"
                    >ダッシュボード
                </router-link>
                <router-link
                    :class="{ open: state.showMenu }"
                    :to="{ path: routePath.PROJECT_SEARCH }"
                    >プロジェクト一覧</router-link
                >
                <router-link
                    :class="{ open: state.showMenu }"
                    :to="{ path: routePath.MEMBERS }"
                    >メンバー一覧</router-link
                >
                <a
                    :class="{ open: state.showMenu, 'router-link-active': state.checkActiveUserProfile }"
                    @click="goToProfile"
                    >プロフィール</a
                >
            </div>
            <div class="nav_bar__container_left" v-if="state.isOrganization">
                <router-link :to="{ path: routePath.ORGANIZATION_DASHBOARD }"
                    >ダッシュボード
                </router-link>
                <router-link
                    :class="{ open: state.showMenu }"
                    :to="{ path: routePath.MEMBERS }"
                    >メンバー一覧</router-link
                >
                <router-link
                    :class="{ open: state.showMenu }"
                    :to="{ path: routePath.TEAMS }"
                    >チーム一覧</router-link
                >
                <router-link
                    :class="{ open: state.showMenu }"
                    :to="{ path: routePath.ORGANIZATION_PROFILE }"
                    >プロフィール</router-link
                >
                <router-link
                    :class="{ open: state.showMenu }"
                    :to="{ path: routePath.ORGANIZATION_NG_LIST }"
                    >NGリスト</router-link
                >
            </div>
            <!-- BGV-751: 利用マニュアルへの導線を非表示
                <div class="nav_bar__container_right" v-if="state.isUser">
                    <a href="/user-manual" target="_blank">使い方ガイド</a>
                </div>
                <div class="nav_bar__container_right" v-if="state.isOrganization">
                  <a href="/enterprise-manual" target="_blank">使い方ガイド</a>
                </div>
            -->
        </div>
      </div>
    </div>
</template>

<script lang="ts" src="@/presentation/components/navigation/Navigation.ts" scoped>
</script>
<style lang="scss" src="@/presentation/components/navigation/Navigation.scss" scoped>
</style>
