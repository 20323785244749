import { defineComponent, reactive, onMounted, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import CommonService from "@/services/CommonService";
import { useStore } from "vuex";
import RoutePath from "@/commons/RoutePath";
import LanguageUtil from "@/commons/LanguageUtil";
export default defineComponent({
    name: "Navigation",
    setup: function () {
        var router = useRouter();
        var route = useRoute();
        var state = reactive({
            showMenu: false,
            isUser: false,
            isOrganization: false,
            accountId: null,
            aLang: LanguageUtil.getInstance().lang,
            checkActiveUserProfile: false
        });
        var routePath = RoutePath;
        var store = useStore();
        function menuTogle() {
            state.showMenu = !state.showMenu;
        }
        function goToProfile() {
            if (route.params.userId && state.accountId !== Number(route.params.userId)) {
                // プロフィール画面でアカウントIDのみが変わる場合は、強制リロード
                location.href = routePath.USER_PROFILE + '/' + state.accountId + '/info';
            }
            else if (route.name != "UserProfileInfo") {
                router.push(routePath.USER_PROFILE + '/' + state.accountId);
            }
            else {
                history.pushState({}, "", routePath.USER_PROFILE + '/' + state.accountId + '/info');
                store.commit('setUserProfileId', Number(state.accountId));
                state.checkActiveUserProfile = true;
            }
        }
        watchEffect(function () {
            if (state.isUser) {
                state.checkActiveUserProfile = window.location.pathname.includes(routePath.USER_PROFILE + "/" + state.accountId) ? true : false;
            }
        });
        onMounted(function () {
            if (CommonService.checkLoginLocal()) {
                var user = CommonService.getCookie("user");
                var user_infor = JSON.parse(decodeURIComponent(user));
                state.accountId = user_infor.account_id;
                store.commit("setIsloginStatus", true);
            }
            if (store.state.isLoggin) {
                state.isUser = CommonService.isUser() ? true : false;
                if (state.isUser) {
                    var user = CommonService.getCookie("user");
                    var user_infor = JSON.parse(decodeURIComponent(user));
                    state.accountId = user_infor.account_id;
                }
                state.isOrganization = CommonService.isOrganization() ? true : false;
            }
            store.subscribe(function (mutation, stateVuex) {
                if (mutation.type === "setHeaderMode" || mutation.type == "setIsloginStatus") {
                    state.isOrganization = CommonService.isOrganization() ? true : false;
                    state.isUser = CommonService.isUser() ? true : false;
                }
            });
        });
        return {
            state: state,
            store: store,
            menuTogle: menuTogle,
            routePath: routePath,
            goToProfile: goToProfile
        };
    }
});
