import { reactive, defineComponent, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import ProjectTitle from "@/presentation/components/project-title/ProjectTitle.vue";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import DIContainer from "@/core/DIContainer";
import OrganizationProjectInteractor from "@/domain/usecases/OrganizationProjectInteractor";
import routePath from "@/commons/RoutePath";
import Navigation from "@/presentation/components/navigation/Navigation.vue";
export default defineComponent({
    name: "ProjectApply",
    components: {
        ProjectTitle: ProjectTitle,
        Breadcrumb: Breadcrumb,
        Navigation: Navigation
    },
    setup: function () {
        var store = useStore();
        var router = useRouter();
        var route = useRoute();
        var organizationProjectInteractor = DIContainer.instance.get(OrganizationProjectInteractor);
        var state = reactive({
            listBreadcrumb: [
                {
                    name: "プロジェクト一覧",
                    route: "/project/search"
                },
                {
                    name: "プロジェクト名XXXXXXXXXX",
                    route: ""
                }
            ]
        });
        onMounted(function () {
            var projectId = route.params.projectId;
            organizationProjectInteractor.getOrganizationProjectById(projectId).then(function (result) {
                if (!result.data) {
                    store.commit('setProjectInfo', {});
                    return;
                }
                store.commit('setProjectInfo', result.data);
                state.listBreadcrumb[1] = {
                    name: result.data.title,
                    route: routePath.PROJECT_DETAIL + result.data.project_id
                };
            }).catch(function (error) {
                router.push(routePath.PAGE_404);
                store.commit('setProjectInfo', {});
            });
        });
        return {
            state: state
        };
    }
});
